import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ConnectionType } from '@capacitor/network';
import { modalController } from '@ionic/core';
import { Env } from '@stencil/core';
import PQueue from 'p-queue';
import { CoreoAPI } from '../../services/api.service';

import AppDatabase, { ProjectAccessDeniedError, ProjectFreeTrialExpiredError, ProjectSyncStep } from '../../services/db/app-db.service';
import {
  clearDb, consolidateMedia, projectFileExists, uninstallProject, writeProjectFile
} from '../../services/db/filesystem.service';
import SQLDatabase from '../../services/db/sql.service';
import MapService from '../../services/maps.service';
import { OfflineMapsService } from '../../services/offline-maps.service';

import theme from '../../services/theme.service';
import { CoreoApp, CoreoAppSummary, CoreoMediaItem } from '../../types';
import { TypeKeys } from '../actions';
import { recordsClearFilter } from '../records/records.actions';
import { ApplicationState } from '../reducers';
import {
  getAppAttachmentsShouldDownload,
  getAppAttachmentsShouldDownloadForApp,
  getAppConfigApiUrl,
  getAppId,
  getAppLocalDatabaseName, getAppMediaDownloadState, getAppOfflineMigrationComplete,
  getAppPage,
  getAppWelcomePageId,
  getApps,
  getAuthToken,
  getCurrentAppWelcomeLastSeen
} from '../selectors';


import { AppEnvironment, AppSettings, AppSyncState, MediaDownloadStatus } from './app.reducer';
import { imageToImageProxy } from '../../services/image.service';

export interface AppInitAction {
  type: TypeKeys.APP_INIT;
}

export interface AppLoadAction {
  type: TypeKeys.APP_LOAD;
  id: number;
  switch: boolean;
}

export interface AppLoadSuccessAction {
  type: TypeKeys.APP_LOAD_SUCCESS;
  app: CoreoApp;
}

export interface AppLoadFailureAction {
  type: TypeKeys.APP_LOAD_FAILURE;
  err: any;
}

export interface AppInstallAction {
  type: TypeKeys.APP_INSTALL;
  id: number;
}

export interface AppInstallSuccessAction {
  type: TypeKeys.APP_INSTALL_SUCCESS;
  app: CoreoAppSummary;
  timestamp: number;
}

export interface AppInstallFailureAction {
  type: TypeKeys.APP_INSTALL_FAILURE;
  err: any;
}

export interface AppUninstallAction {
  type: TypeKeys.APP_UNINSTALL;
  id: number;
}

export interface AppTrialExpiredAction {
  type: TypeKeys.APP_TRIAL_EXPIRED;
  id: number;
}

export interface AppAccessDeniedAction {
  type: TypeKeys.APP_ACCESS_DENIED;
  id: number;
}

export interface AppAvailableAction {
  type: TypeKeys.APP_AVAILABLE;
  id: number;
}
export interface AppWelcomeSeenAction {
  type: TypeKeys.APP_WELCOME_SEEN;
  id: number;
  pageId: number;
}

export interface AppDismissTutorial {
  type: TypeKeys.APP_DISMISS_TUTORIAL;
}

export interface AppsResetAction {
  type: TypeKeys.APPS_RESET;
}

export interface AppDownloadMediaAction {
  type: TypeKeys.APP_DOWNLOAD_MEDIA;
  id: number;
  progress?: number;
}

export interface AppDownloadMediaSuccessAction {
  type: TypeKeys.APP_DOWNLOAD_MEDIA_SUCCESS;
  id: number;
}
export interface AppDownloadMediaErrorAction {
  type: TypeKeys.APP_DOWNLOAD_MEDIA_ERROR;
  id: number;
  error: any;
}

export interface AppDownloadAttachmentsAction {
  type: TypeKeys.APP_DOWNLOAD_ATTACHMENTS;
  id: number;
  progress?: number;
}

export interface AppDownloadAttachmentsSuccessAction {
  type: TypeKeys.APP_DOWNLOAD_ATTACHMENTS_SUCCESS;
  id: number;
}
export interface AppDownloadAttachmentsErrorAction {
  type: TypeKeys.APP_DOWNLOAD_ATTACHMENTS_ERROR;
  id: number;
  error: any;
}

export interface AppSwitchEnvironmentAction {
  type: TypeKeys.APP_SWITCH_ENVIRONMENT;
  env?: AppEnvironment;
}

export interface AppSyncAction {
  type: TypeKeys.APP_SYNC;
  id: number;
}

export interface AppSyncSuccessAction {
  type: TypeKeys.APP_SYNC_SUCCESS;
  id: number;
  timestamp: number;
}

export interface AppSyncErrorAction {
  type: TypeKeys.APP_SYNC_ERROR;
  id: number;
  error: any;
}

export interface AppOfflineMigrationCompleteAction {
  type: TypeKeys.APP_OFFLINE_MIGRATION_COMPLETE;
}

export interface AppUpdateSettingsAction {
  type: TypeKeys.APP_UPDATE_SETTINGS;
  settings: Partial<AppSettings>;
};

export interface AppUpdateNetworkAction {
  type: TypeKeys.APP_UPDATE_NETWORK;
  networkConnected: boolean;
  networkConnectionType: ConnectionType;
}

export interface AppUpdateSyncStateAction {
  type: TypeKeys.APP_SYNC_STATE_UPDATE;
  id: number;
  syncState: AppSyncState;
}

export type AppActions =
  | AppInitAction
  | AppLoadAction
  | AppLoadSuccessAction
  | AppLoadFailureAction
  | AppInstallAction
  | AppInstallSuccessAction
  | AppInstallFailureAction
  | AppUninstallAction
  | AppDismissTutorial
  | AppWelcomeSeenAction
  | AppsResetAction
  | AppDownloadMediaAction
  | AppDownloadMediaSuccessAction
  | AppDownloadMediaErrorAction
  | AppSwitchEnvironmentAction
  | AppSyncAction
  | AppSyncSuccessAction
  | AppTrialExpiredAction
  | AppAvailableAction
  | AppAccessDeniedAction
  | AppOfflineMigrationCompleteAction
  | AppDownloadAttachmentsAction
  | AppDownloadAttachmentsSuccessAction
  | AppDownloadAttachmentsErrorAction
  | AppUpdateSettingsAction
  | AppUpdateNetworkAction
  | AppUpdateSyncStateAction
  ;

export const appInit = (): AppInitAction => ({
  type: TypeKeys.APP_INIT
});

export const appTrialExpired = (id: number): AppTrialExpiredAction => ({
  type: TypeKeys.APP_TRIAL_EXPIRED,
  id
});

export const appAccessDenied = (id: number): AppAccessDeniedAction => ({
  type: TypeKeys.APP_ACCESS_DENIED,
  id
});

export const appAvailable = (id: number): AppAvailableAction => ({
  type: TypeKeys.APP_AVAILABLE,
  id
});

export const appLoad = (id: number, isSwitch: boolean = false): AppLoadAction => ({
  type: TypeKeys.APP_LOAD,
  id,
  switch: isSwitch
});

export const appLoadSuccess = (app: CoreoApp): AppLoadSuccessAction => ({
  type: TypeKeys.APP_LOAD_SUCCESS,
  app
});

export const appLoadFailure = (err: string): AppLoadFailureAction => ({
  type: TypeKeys.APP_LOAD_FAILURE,
  err
});

export const appInstallApp = (id: number): AppInstallAction => ({
  type: TypeKeys.APP_INSTALL,
  id
});

export const appInstallAppSuccess = (app: CoreoAppSummary): AppInstallSuccessAction => ({
  type: TypeKeys.APP_INSTALL_SUCCESS,
  app,
  timestamp: Date.now()
});

export const appInstallAppFailure = (err: any): AppInstallFailureAction => ({
  type: TypeKeys.APP_INSTALL_FAILURE,
  err
});

export const appUninstallApp = (id: number): AppUninstallAction => ({
  type: TypeKeys.APP_UNINSTALL,
  id
});

export const appDismissTutorial = (): AppDismissTutorial => ({
  type: TypeKeys.APP_DISMISS_TUTORIAL
});

export const appWelcomeSeen = (id: number, pageId: number): AppWelcomeSeenAction => ({
  type: TypeKeys.APP_WELCOME_SEEN,
  id,
  pageId
});

export const appDownloadMedia = (id: number, progress?: number): AppDownloadMediaAction => ({
  type: TypeKeys.APP_DOWNLOAD_MEDIA,
  id,
  progress
});

export const appDownloadMediaSuccess = (id: number): AppDownloadMediaSuccessAction => ({
  type: TypeKeys.APP_DOWNLOAD_MEDIA_SUCCESS,
  id
});

export const appDownloadMediaError = (id: number, error: any): AppDownloadMediaErrorAction => ({
  type: TypeKeys.APP_DOWNLOAD_MEDIA_ERROR,
  id,
  error
});

export const appDownloadAttachments = (id: number, progress?: number): AppDownloadAttachmentsAction => ({
  type: TypeKeys.APP_DOWNLOAD_ATTACHMENTS,
  id,
  progress
});

export const appDownloadAttachmentsSuccess = (id: number): AppDownloadAttachmentsSuccessAction => ({
  type: TypeKeys.APP_DOWNLOAD_ATTACHMENTS_SUCCESS,
  id
});

export const appDownloadAttachmentsError = (id: number, error: any): AppDownloadAttachmentsErrorAction => ({
  type: TypeKeys.APP_DOWNLOAD_ATTACHMENTS_ERROR,
  id,
  error
});

export const appUpdateSyncState = (id: number, syncState: AppSyncState): AppUpdateSyncStateAction => ({
  type: TypeKeys.APP_SYNC_STATE_UPDATE,
  id,
  syncState
});

export const appSwitchEnvironment = (env?: AppEnvironment) => async (dispatch, getState) => {
  dispatch({
    type: TypeKeys.APP_SWITCH_ENVIRONMENT,
    env
  });
  const state = getState();

  const apiUrl = getAppConfigApiUrl(state);
  const dbName = getAppLocalDatabaseName(state);

  CoreoAPI.instance.setApiUrl(apiUrl);
  CoreoAPI.instance.setAuthToken(null);

  await SQLDatabase.instance.init(dbName);
  await AppDatabase.instance.init();
};

export const appSync = (id: number): AppSyncAction => ({
  type: TypeKeys.APP_SYNC,
  id,
});

export const appSyncSuccess = (id: number, timestamp: number = new Date().valueOf()): AppSyncSuccessAction => ({
  type: TypeKeys.APP_SYNC_SUCCESS,
  id,
  timestamp
});

export const appSyncError = (id: number, error: any): AppSyncErrorAction => ({
  type: TypeKeys.APP_SYNC_ERROR,
  id,
  error
});

export const appOfflineMigrationComplete = (): AppOfflineMigrationCompleteAction => ({
  type: TypeKeys.APP_OFFLINE_MIGRATION_COMPLETE
});

export const appUpdateSettings = (settings: Partial<AppSettings>): AppUpdateSettingsAction => ({
  type: TypeKeys.APP_UPDATE_SETTINGS,
  settings
});

export const appUpdateNetwork = (networkConnected: boolean, networkConnectionType: ConnectionType): AppUpdateNetworkAction => ({
  type: TypeKeys.APP_UPDATE_NETWORK,
  networkConnected,
  networkConnectionType
});

export const init = async (dispatch, getState) => {
  const state = getState();
  const appId = getAppId(state);

  // Initialize the API Service
  const apiUrl = getAppConfigApiUrl(state);
  const authToken = getAuthToken(state);

  CoreoAPI.instance.setApiUrl(apiUrl);
  CoreoAPI.instance.setAuthToken(authToken);

  if (Capacitor.isNativePlatform()) {
    const appInfo = await App.getInfo();
    CoreoAPI.instance.setAppDetails(appInfo.version, `${appInfo.build} (${Env.commit})`);
  } else {
    CoreoAPI.instance.setAppDetails('web', Env.commit);
  }

  const databaseName = getAppLocalDatabaseName(state);
  await SQLDatabase.instance.init(databaseName);
  await AppDatabase.instance.init();
  await OfflineMapsService.instance.init();
  await MapService.instance.init();

  // Only attempt to load an app if we have completed offline migration
  const offlineMigrationComplete = getAppOfflineMigrationComplete(state);

  if (offlineMigrationComplete && appId !== null) {
    await loadApp(appId)(dispatch, getState);
  }
  dispatch(appInit());
};


export const loadApp = (id: number, showWelcome = true) => async (dispatch, getState) => {

  const state: ApplicationState = getState();
  const isSwitch = id !== state.app.appId;
  dispatch(appLoad(id, isSwitch));
  dispatch(recordsClearFilter());

  try {
    const app: CoreoApp = await AppDatabase.instance.loadProject(id);

    // Update the theme
    theme.reset();
    theme.update(app);


    dispatch(appLoadSuccess(app));

    if (showWelcome) {
      dispatch(showAppWelcomeIfNeeded(id));
    }
  } catch (e) {
    console.error(e)
    dispatch(appLoadFailure(e));
  }
};

export const reloadApp = (app: CoreoApp, showWelcome = true) => async (dispatch) => {
  dispatch(appLoad(app.id));

  try {
    // Update the theme
    theme.reset();
    theme.update(app);

    dispatch(appLoadSuccess(app));

    if (showWelcome) {
      dispatch(showAppWelcomeIfNeeded(app.id));
    }
  } catch (e) {
    console.error(e)
    dispatch(appLoadFailure(e));
  }
};


export const uninstallApp = (id: number) => async (dispatch) => {
  await uninstallProject(id);
  await AppDatabase.instance.deleteProject(id);
  dispatch(appUninstallApp(id));
};

export const refreshAppStatus = (id: number) => async (dispatch) => {
  try {
    const project = await AppDatabase.instance.getProjectFromAPI(id);

    if (!project.freeTrialExpired) {
      dispatch(appAvailable(id));
      return true;
    }
    return false;
  } catch (e) {
    console.warn(e);
    return false;
  }
}

export const appsReset = () => async dispatch => {
  await clearDb();
  await SQLDatabase.instance.dropAllTables();
  dispatch({
    type: TypeKeys.APPS_RESET
  });
};

export const showAppWelcomeIfNeeded = (id: number) => async (dispatch, getState) => {
  const state = getState();
  const welcomeLastSeen = getCurrentAppWelcomeLastSeen(state);
  const welcomePageId = getAppWelcomePageId(state);
  if (welcomePageId === null || !welcomeLastSeen) {
    return;
  }
  const welcomePage = getAppPage(welcomePageId)(state);
  if (!welcomePage) {
    return;
  }

  const welcomePageLastUpdated = welcomePage.updatedAt;

  if (
    welcomeLastSeen.timestamp === null
    || new Date(welcomePageLastUpdated) > new Date(welcomeLastSeen.timestamp)
    || welcomePageId !== welcomeLastSeen.id) {
    setTimeout(async () => {
      const modal = await modalController.create({
        component: 'app-page',
        componentProps: {
          pageId: welcomePageId,
          showCloseFooter: true
        }
      });
      modal.present();
      dispatch(appWelcomeSeen(id, welcomePageId));
    }, 250);
  }
}

const attachmentDownloadUrl = (url: string, mimeType: string) => {
  if (!(mimeType?.startsWith('image'))) {
    return url;
  }
  return imageToImageProxy(url, { output: 'auto' });
};

const mapImageDownloadUrl = (url: string) => {
  return imageToImageProxy(url);
}

export const downloadMediaItem = async (id: number, item: CoreoMediaItem): Promise<any> => {
  const downloadUrl = attachmentDownloadUrl(item.url, item.type);
  const response = await fetch(downloadUrl, {
    cache: 'default'
  });
  const data = await response.blob();
  await writeProjectFile(id, data, 'media', item.id);
  // completed++;
  // dispatch(appDownloadMedia(id, (completed / toDownload.length) * 100));
}

export const downloadMedia = (id: number) => async (dispatch, getState) => {

  // Check if this is already running
  const mediaDownloadState = getAppMediaDownloadState(id)(getState());

  if (mediaDownloadState.state === MediaDownloadStatus.DOWNLOADING) {
    return;
  }

  const currentMedia = await AppDatabase.instance.mediaItems.query({ projectId: id });
  const toDownload = await consolidateMedia(id, 'media', currentMedia);

  if (toDownload.length === 0) {
    return dispatch(appDownloadMediaSuccess(id));
  }

  // let completed = 0;
  dispatch(appDownloadMedia(id, 0));

  const queue = new PQueue({
    concurrency: 6
  });

  for (const item of toDownload) {
    queue.add(() => {
      return downloadMediaItem(id, item).then(() => {
        // completed++;
        // dispatch(appDownloadMedia(id, (completed / toDownload.length) * 100));
      });
    });
  }
  await queue.onIdle();
  return dispatch(appDownloadMediaSuccess(id));
};

export const downloadAttachments = (id: number) => async (dispatch, getState) => {

  // If settings say we shouldn't do anything now, do nothing
  if (!getAppAttachmentsShouldDownload(getState())) {
    return;
  }

  // Make sure we don't get stuck on a particular attacment that won't download
  const visited: Set<number> = new Set<number>();

  let toSync = await AppDatabase.instance.attachments.getUnsynchronised(id);

  // If we have nothing to do, exit
  if (toSync.length === 0) {
    return dispatch(appDownloadAttachmentsSuccess(id));
  }

  // let completed = 0;

  dispatch(appDownloadAttachments(id, 0));

  while (toSync.length > 0) {
    for (const attachment of toSync) {
      visited.add(attachment.id);
      const downloadUrl = attachmentDownloadUrl(attachment.url, attachment.mimeType);
      const response = await fetch(downloadUrl);
      const data = await response.blob();
      const path = await writeProjectFile(id, data, 'attachments', attachment.id);
      // completed++;
      // dispatch(appDownloadAttachments(id, (completed / toSync.length) * 100));

      // Wait for a transaction so that we ensure we have a db lock
      const transaction = await SQLDatabase.instance.transaction();
      await transaction.executeSet([AppDatabase.instance.attachments.setFileLocationSet(attachment.id, path)]);
      await transaction.commit();

      // Check that we should keep going
      if (!getAppAttachmentsShouldDownloadForApp(id)(getState())) {
        return;
      }
    }

    // Go round again
    toSync = (await AppDatabase.instance.attachments.getUnsynchronised(id)).filter(a => !visited.has(a.id));
    // completed = 0;
  }

  dispatch(appDownloadAttachmentsSuccess(id));
};

export const downloadMapImagery = (id: number) => async () => {
  const imageryLayers = await AppDatabase.instance.mapLayers.query({ sourceType: 'image', projectId: id });
  for (const layer of imageryLayers) {
    const url = new URL(layer.source);
    const filename = url.pathname.split('/').pop();
    const exists = await projectFileExists(id, 'maps', filename);
    if (!exists) {
      const url = mapImageDownloadUrl(layer.source);
      const response = await fetch(url);
      const data = await response.blob();
      await writeProjectFile(id, data, 'maps', filename);
    }
  }
};


export const appOfflineMigration = () => async (dispatch, getState) => {
  const state = getState();
  const apps = getApps(state);

  const migrateApp = async (id: number): Promise<void> => {

    const app = await AppDatabase.instance.syncProject(id, { force: true });
    dispatch(appInstallAppSuccess(app));
    dispatch(appSyncSuccess(id));
  };

  for (const app of apps) {
    await migrateApp(app.id);
  }

  dispatch(appOfflineMigrationComplete());

  // Load the first app
  dispatch(loadApp(apps[0].id, false));
};

interface SyncProjectOptions {
  force?: boolean;
  reload?: boolean;
  step?: ProjectSyncStep;
}

export const syncProject = (id: number, options: SyncProjectOptions = { force: false, reload: true, step: null }) => async (dispatch, getState) => {
  const force = options.force ?? false;
  const reload = options.reload ?? true;

  dispatch(appSync(id));

  try {
    const app = await AppDatabase.instance.syncProject(id, { force, step: options.step });
    dispatch(appSyncSuccess(id));
    downloadMedia(id)(dispatch, getState);
    downloadAttachments(id)(dispatch, getState);
    downloadMapImagery(id)();
    if (app && reload) {
      await reloadApp(app)(dispatch);
    }
  } catch (e) {
    dispatch(appSyncError(id, e));

    if (e instanceof ProjectFreeTrialExpiredError) {
      dispatch(appTrialExpired(id))
    }
    if (e instanceof ProjectAccessDeniedError) {
      dispatch(appAccessDenied(id));
    }

    throw e;
  }
};

export const installProject = (id: number) => async (dispatch, getState) => {
  dispatch(appSync(id));
  try {
    const app = await AppDatabase.instance.syncProject(id, { force: true });
    dispatch(appInstallAppSuccess(app));
    dispatch(appSyncSuccess(id));
    downloadMedia(id)(dispatch, getState);
    downloadAttachments(id)(dispatch, getState);
    downloadMapImagery(id)();
  } catch (e) {
    console.warn(e);
  }
};

